<template>
  <b-form>
    <b-row>
      <b-col
        cols="12"
        class="text-center my-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="danger"
          @click="deleteAccount"
        >
          <feather-icon
            icon="Trash2Icon"
            class="mr-50"
          />
          <span>{{ $t("auth.delete_account") }} </span>
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BButton,
} from 'bootstrap-vue'
import { showToast } from '@/mixins/notification/toasts'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { useUserUi } from '@/views/apps/user/useUser'
export default {
  components: {
    BRow, BCol, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [showToast],
  props: {
    accountData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    displayDeletionStatus() {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: `${this.$t('auth.the_account_has_been_deleted')}`,
          icon: 'BellIcon',
          variant: 'success',
          text: this.connectionStatus,
        },
      })
    },
    deleteAccount() {
      this.$swal({
        title: this.$t('Are you sure?'),
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__fadeIn',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch('account/deleteAccountSettings')
            .then(response => {
              this.displayDeletionStatus()
              this.logout()
            })
        }
      })
    },
  },
  setup() {
    const { logout } = useUserUi()
    return {
      logout,
    }
  },
}
</script>

<style>

</style>
